<template>
  <svg
      width="300"
      height="273"
      viewBox="0 0 300 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
  >
    <path
        d="M100 0L143.301 25V75L100 100L56.6987 75V25L100 0Z"
        fill="#F15D3B"
    />
    <path
        d="M50 86L93.3013 111V161L50 186L6.69873 161V111L50 86Z"
        fill="url(#tf-gradient)"
    />
    <path
        d="M150 89L193.301 114V164L150 189L106.699 164V114L150 89Z"
        fill="url(#tf-gradient)"
    />
    <path
        d="M250 89L293.301 114V164L250 189L206.699 164V114L250 89Z"
        fill="url(#tf-gradient)"
    />
    <path
        d="M200 0L243.301 25V75L200 100L156.699 75V25L200 0Z"
        fill="url(#tf-gradient)"
    />
    <path
        d="M100 173L143.301 198V248L100 273L56.6987 248V198L100 173Z"
        fill="url(#tf-gradient)"
    />
    <path
        d="M200 173L243.301 198V248L200 273L156.699 248V198L200 173Z"
        fill="#F15D3B"
    />

    <defs>
      <linearGradient
          id="tf-gradient"
          x1="45.0389"
          y1="-77.625"
          x2="-88.8544"
          y2="-4.93514"
          radientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F1BD00"/>
        <stop offset="1" stop-color="#F15D3B"/>
      </linearGradient>
    </defs>
  </svg>
</template>
